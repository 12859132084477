.mobile-bottom-show-up {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #3c5b9c;
  -webkit-animation-name: menu-open;
  -moz-animation-name: menu-open;
  -o-animation-name: menu-open;
  animation-name: menu-open;
  -webkit-animation-duration: 0.3s;
  -moz-animation-duration: 0.3s;
  -o-animation-duration: 0.3s;
  animation-duration: 0.3s;
}




@keyframes menu-open {
  0% {top: 1000px}
  100% {top: 0}
}

.mobile-bottom-show-up-small-dialog {
  animation: dialog-small-open 0.3s forwards !important;
  animation-delay: 0.3s;
}

@keyframes dialog-small-open {
  0% { margin-bottom: -360px }
  100% { margin-bottom: 0 }
}


.mobile-bottom-show-up-search-filter-dialog {
  animation: dialog-search-filter-open 0.3s forwards !important;
  animation-delay: 0.3s;
}

@keyframes dialog-search-filter-open {
  0% { margin-top: 1000px }
  100% { margin-bottom: 0 }
}


.loading-icon {
  animation: 1s linear 0s normal none infinite running rot;
  -webkit-animation: 1s linear 0s normal none infinite running rot;
}
@keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
