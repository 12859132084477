@import '../style-variables';

.h1, .h2, .h3, .h4, .body-1, .body-2 {
  color: $black-color;
}

.h1 {
  font: normal normal bold 44px/36px Roboto !important;
}

.h2 {
  font: normal normal bold 24px/28px Roboto !important;
}

.h3 {
  font: normal normal bold 16px/24px Roboto  !important;;
}

.h4 {
  font: normal normal bold 14px/20px Roboto !important;
}

.body-1 {
  font: normal normal normal 16px/24px Roboto;
}

.body-2 {
  font: normal normal normal 14px/20px Roboto;
}

.caption-1, .caption-2, .caption-3, .caption-4 {
  text-decoration: none;
}

.caption-1, .caption-2 {
  font: normal normal normal 14px/20px Roboto;
}

.caption-1 {
  color: var(--secondary-50-color);
}

.caption-2 {
  color: var(--secondary-40-color);
}

.caption-3, .caption-4 {
  font: normal normal normal 12px/14px Roboto;
}

.caption-3 {
  color: var(--secondary-50-color);
}

.caption-3.dark {
  color: var(--primary-40-color);
}

.caption-4 {
  color: var(--secondary-40-color);
}

.link-1, .link-2, .link-3, .link-4, .link-5, .link-6,.link-7 {
  color: $primary-color !important;
  text-decoration: none;
  cursor: pointer !important;
  &:hover {
    color: var(--primary-50-color) !important;;
  }
  &:active {
    color: var(--primary-70-color) !important;;
  }
}

.link-1 {
  font: normal normal bold 16px/24px Roboto;
}

.link-2 {
  font: normal normal normal 16px/24px Roboto;
}

.link-3 {
  font: normal normal normal 14px/20px Roboto;
}

.link-4 {
  font: normal normal bold 14px/20px Roboto;
}

.link-5 {
  font: normal normal normal 12px/14px Roboto;
}

.link-6 {
  font: normal normal bold 12px/14px Roboto;
}

.link-7 {
  font: normal normal normal 12px/20px Roboto;
}

.overline, .overline-link, .overline-2 {
  text-transform: uppercase;
}

.overline-link {
  font: normal normal bold 16px/14px Roboto !important;
}

.overline {
  font: normal normal bold 16px/14px Roboto;
}

.overline-2 {
  font: normal normal bold 12px/14px Roboto;
}

.td-n {
  text-decoration: none;
}

.inactive-color {
  color: var(--secondary-40-color);
}

.inactive-color-50 {
  color: var(--secondary-50-color);
}

.inactive-color-70 {
  color: var(--secondary-70-color);
}

.primary-color {
  color: $primary-color;
}

.primary-color-50 {
  color: var(--primary-50-color)
}

.primary-color-60 {
  color: var(--primary-60-color)
}

.primary-color-70 {
  color: var(--primary-70-color)
}

.dark-gray-color {
  color: var(--secondary-50-color);
}

.cursiv {
  font-style: italic;
}

.dark-color {
  color: var(--secondary-70-color);
}


.white-color {
  color: white !important;
}


@media screen and (max-width: 1366px) {
  .link-1, .link-2, .link-3, .link-4, .link-5, .link-6,.link-7 {
    &:hover {
      color: $primary-color !important;
    }
    &:active {
      color: var(--primary-70-color) !important;
    }
  }
}

@media screen and (max-width: 1024px) {
  .link, .link-1, .link-2, .link-3, .link-4, .link-5, .link-6, .link-7 {
    cursor: auto !important;
  }
}

@media screen and (max-width: 768px) {

  .body-1 {
    font: normal normal normal 14px/20px Roboto;
  }


  /*  .body-2 {
      font: normal normal normal 12px/14px Roboto;
    }*/

/*  .link-3 {
    font: normal normal normal 12px/14px Roboto;
  }*/

  .caption-1 {
    font: normal normal normal 12px/14px Roboto;
  }
}

@media screen and (max-width: 575px) {
  .h1 {
    font: normal normal bold 24px/28px Roboto !important;
  }
}

@media screen and (max-width: 360px) {
/*  .link-3 {
    font: normal normal normal 12px/14px Roboto;
  }*/
  .caption-2{
    font: normal normal normal 12px/14px Roboto;
  }

  .overline-link {
    font: normal normal bold 14px/14px Roboto !important;
  }
}
