:root {
  --white-100-color: #000000;
  --white-72-color: #474747;
  --white-56-color: #707070;
  --white-24-color: #c2c2c2;
  --white-12-color: #e0e0e0;
  --white-0-color: #ffffff;

  --black-100-color: #ffffff;
  --black-72-color: #e0e0e0;
  --black-56-color: #c2c2c2;
  --black-24-color: #707070;
  --black-12-color: #474747;
  --black-0-color: #000000;

  --blue-100-color: #065075;
  --blue-72-color: #4b809b;
  --blue-56-color: #739db1;
  --blue-24-color: #92bbd2;
  --blue-12-color: #a8cce0;
  --blue-0-color: #bfddef;

  --cream-100-color: #5e574f;
  --cream-72-color: #8a8580;
  --cream-56-color: #a4a09c;
  --cream-24-color: #b2b0a5;
  --cream-12-color: #cecbbe;
  --cream-0-color: #eae7d8;

  --brown-green-100-color: #94e21a;
  --brown-green-72-color: #82c717;
  --brown-green-56-color: #71ac14;
  --brown-green-24-color: #6a5b4e;
  --brown-green-12-color: #534132;
  --brown-green-0-color: #3b2716;

  --current-100-color: var(--white-100-color);
  --current-72-color: var(--white-72-color);
  --current-56-color: var(--white-56-color);
  --current-24-color: var(--white-24-color);
  --current-12-color: var(--white-12-color);
  --current-0-color: var(--white-0-color);

  --font-arial: Arial;
  --font-times-new-roman: Times New Roman;

  --normal-letter-spacing: 0px;
  --medium-letter-spacing: 1px;
  --big-letter-spacing: 2px;

  --current-letter-spacing: var(--normal-letter-spacing);

  --button-padding: 11px;
  --side-padding: 32px;
  --root-font-size: 10px;
  --root-font-family: var(--font-arial);
  --root-letter-spacing: normal;
  --text-decoration-thickness: 2px;
  --small-size: 32px;
  --medium-size: 40px;
  --big-size: 56px;
  --big-big-size: 59px;
  @media screen and (max-width: 768px) {
    --side-padding: 5px;
    --button-padding: 2px;
    --text-decoration-thickness: 0.7px;
    --small-size: 16px;
    --medium-size: 20px;
    --big-size: 28px;
    --big-big-size: 31px;
  }
}

a {
  text-decoration: none;
}
.cdk-version-for-visually-impaired {
  font: normal normal normal var(--root-font-size) / 14px var(--root-font-family);
  .mat-menu-item {
    font: normal normal normal var(--root-font-size) / 14px var(--root-font-family);
  }
}

.default-text-size {
  font: normal normal normal var(--root-font-size) / 14px var(--root-font-family) !important;
}

input[type='text'].impaired {
  width: 100%;
  padding: 0 12px;
}

.text-impaired {
  color: var(--current-100-color);
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  letter-spacing: var(--current-letter-spacing);
}

.link-impaired {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-thickness: var(--text-decoration-thickness);
  @extend .text-impaired;
  &:hover {
    color: var(--current-72-color);
  }
}

.link3-impaired {
  font-size: 160%;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: calc(0.4px + var(--current-letter-spacing));
  @extend .link-impaired;
}

.link4-impaired {
  font-size: 180%;
  line-height: 155%;
  letter-spacing: calc(0.7px + var(--current-letter-spacing));
  @extend .link-impaired;
}

.body1-impaired {
  font-size: 180%;
  line-height: 155%;
  letter-spacing: calc(0.7px + var(--current-letter-spacing));
  @extend .text-impaired;
}

.body2-impaired {
  font-size: 160%;
  line-height: 150%;
  letter-spacing: calc(0.4px + var(--current-letter-spacing));
  @extend .text-impaired;
}

.body3-impaired {
  font-size: 140%;
  line-height: 142%;
  letter-spacing: calc(0.1px + var(--current-letter-spacing));
  @extend .text-impaired;
}

.body3-impaired-dark {
  @extend .body3-impaired;
  color: var(--current-0-color);
}

.h1-impaired {
  font-size: 320%;
  font-weight: bold;
  line-height: 125%;
  letter-spacing: calc(2.48px + var(--current-letter-spacing));
  @extend .text-impaired;
}

.h2-impaired {
  font-size: 240%;
  font-weight: bold;
  line-height: 117%;
  letter-spacing: calc(1.36px + var(--current-letter-spacing));
  @extend .text-impaired;
}

.h3-impaired {
  font-size: 180%;
  font-weight: bold;
  line-height: 155%;
  letter-spacing: calc(0.7px + var(--current-letter-spacing));
  @extend .text-impaired;
}
.h3-impaired-dark {
  @extend .h3-impaired;
  color: var(--current-0-color);
}

.h4-impaired {
  font-size: 160%;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: calc(0.4px + var(--current-letter-spacing));
  @extend .text-impaired;
}

.button1-impaired-text {
  @extend .text-impaired;
  font-size: 180%;
  font-weight: bold;
  line-height: 120%;
  text-transform: uppercase;
  letter-spacing: calc(1.3px + var(--current-letter-spacing));
}

.button2-impaired-text {
  @extend .text-impaired;
  font-weight: bold;
  font-size: 160%;
  line-height: 150%;
  letter-spacing: calc(0.9px + var(--current-letter-spacing));
  text-transform: uppercase;
}

.button2-impaired-text-dark {
  @extend .button2-impaired-text;
  color: var(--current-0-color);
}

.medium-impaired {
  height: var(--medium-size);
  width: var(--medium-size);
}

.small-impaired {
  height: var(--small-size);
  width: var(--small-size);
}

.big-impaired {
  height: var(--big-size);
  width: var(--big-size);
}

.impaired-size-32 {
  width: var(--small-size);
  height: var(--small-size);
}

.impaired-size-56 {
  width: var(--big-size);
  height: var(--big-size);
}
