@import 'style-variables';
@import '@angular/material/theming';
@import './styles/typography';
@import './styles/margins';
@import './styles/controls';
@import './styles/material-redefinition.scss';
@import './styles/animations';
@import './styles/version-for-visually-impired';
@import './styles/version-for-visually-impired-controls';

@include mat-core();
$opod: (
  50: var(--mat-50),
  100: var(--mat-100),
  200: var(--mat-200),
  300: var(--mat-300),
  400: var(--mat-400),
  500: var(--mat-500),
  600: var(--mat-600),
  700: var(--mat-700),
  800: var(--mat-800),
  900: var(--mat-900),
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
  )
);

$my-app-primary: mat-palette($opod, 600);
$my-app-accent: mat-palette($opod, 600);
$my-app-warn: mat-palette($mat-deep-orange);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
@include angular-material-theme($my-app-theme);

.mat-checkbox-frame {
  border-color: var(--mat-900);
}

:root {
  --toolbar-height: 75px;
  --data-filters-height: 64px;
  --paginator-height: 48px;
  --static-table-min-width: 250px;

  --mat-checkbox-size: 16px;
  --mat-checkbox-margin-right: 8px;

  --scrollbar-width: 8px;
  --scrollbar-height: 8px;

  --mat-900: #10255a;
  --mat-800: #10255a;
  --mat-700: #3c5b9c;
  --mat-600: #698cce;
  --mat-500: #698cce;
  --mat-400: #a0bfee;
  --mat-300: #cfdff6;
  --mat-200: #cfdff6;
  --mat-100: #cfdff6;
  --mat-50: #cfdff6;

  --primary-70-color: #10255a;
  --primary-60-color: #3c5b9c;
  --primary-50-color: #698cce;
  --primary-40-color: #a0bfee;
  --primary-30-color: #cfdff6;
  --primary-70-32-color: #10255a52;

  --secondary-70-color: #0e0e0f;
  --secondary-60-color: #3c3e41;
  --secondary-50-color: #696c71;
  --secondary-40-color: #b7bbb2;
  --secondary-30-color: #e6e3df;
  --secondary-20-color: #eeeff2;
  --secondary-10-color: #f3f5f7;
  --secondary-0-color: #ffffff;

  --negative-color: #aa0000;
  --neutral-color: #ffaa00;
  --positive-color: #008844;
  --blue-gradient: linear-gradient(103deg, #10265a 0%, #3c5b9c 100%) 0 0 no-repeat padding-box;
}

.content-container {
  width: $container-l;
  margin: 0 auto;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  height: var(--scrollbar-height);
  width: var(--scrollbar-width);
}

::-webkit-scrollbar-thumb {
  background-color: var(--secondary-40-color);
  border-radius: 4px;
  height: 40px;
}
.cdk-overlay-panel-custom {
  z-index: 59 !important;
}

/*::-webkit-scrollbar {
  height: 4px;
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--secondary-40-color);
  border-radius: 4px;
  width: 100%;
  border: 4px solid transparent;
  background-clip: padding-box; // <== make the border work
}


::-webkit-scrollbar-thumb:hover {
  background-color: var(--secondary-40-color);
  border: 0;
}*/

@media screen and (max-width: 1300px) {
  .content-container {
    width: $container-m;
  }
}

@media screen and (max-width: 1020px) {
  .content-container {
    width: auto;
    margin: 0 24px;
  }
}

@media screen and (max-width: $container-s) {
  .content-container {
    margin: 0 16px;
  }
}

html, body {
  overflow-y: hidden !important;
}

html,
body,
app-root,
mat-tab-body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, sans-serif;
}

.no-scroll {
  overflow-y: hidden;
  overflow-x: hidden;
}

.no-scroll-impaired {
  @extend .no-scroll;
  width: 100%;
}

.responsive-container {
  width: calc(100vw - 72px);
  margin: 0 auto;
}

.svg14 {
  width: 14px;
  height: 14px;
}

.svg16 {
  width: 16px;
  height: 16px;
}

.svg18 {
  width: 18px;
  height: 18px;
}

.svg20 {
  width: 20px;
  height: 20px;
}

.svg22 {
  width: 22px;
  height: 22px;
}

.svg24 {
  width: 24px;
  height: 24px;
}

.svg28 {
  width: 28px;
  height: 28px;
}

.svg32 {
  width: 32px;
  height: 32px;
}

.svg40 {
  width: 40px;
  height: 40px;
}

.svg48-40 {
  width: 48px;
  height: 40px;
}

.code-section {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding-left: 20px !important;
}

.error-svg {
  width: 156px;
  height: 102px;
}

.center {
  display: grid;
}

.df {
  display: flex !important;
}

.gray-title {
  text-align: left;
  font: $font-normal;
  letter-spacing: 0;
  color: #696c71;
  align-items: center;
  display: flex;
}

.black-text {
  text-align: left;
  font: $font-normal;
  letter-spacing: 0;
  color: #0e0e0f;
}

.horizontal-line {
  width: 100%;
  background: #dfe1e6;
  height: 1px;
}

button {
  cursor: pointer;
}

.button-close {
  background: #698cce 0 0 no-repeat padding-box;
  border-radius: 20px;
  padding: 7px;
  border: 1px solid #b2b5bb;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  max-width: 90%;

  span {
    text-align: left;
    font: $font-normal;

    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
  }

  mat-icon {
    width: 14px;
    height: 14px;
    background: white;
    border-radius: 20px;
    margin-left: 8px;
    cursor: pointer;
    color: #698cce;
  }
}

.button-padding {
  padding: 8px 20px 8px 20px;
}

.default-snackbar {
  margin-top: calc(var(--toolbar-height) + 10px) !important;
  padding: 0 !important;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}

.closer-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: var(--primary-70-32-color);
  z-index: 2;
}

.closer-backdrop-transparent {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 2;
}

.footer-text {
  display: block;
  color: var(--primary-30-color);
  white-space: normal;
  text-align: left;
}

.footer-link {
  color: var(--primary-40-color);
}

.footer-link:hover,
.icon-links:hover .social-icon {
  color: var(--primary-50-color);
}

.scroll-hider {
  position: absolute;
  width: 100%;
  height: 40px;
  background-color: white;
  bottom: 0;
}

.backdrop {
  display: block;
  position: fixed;
  z-index: 1;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  background-color: var(--primary-70-32-color);
}

.table-edge-margin {
  padding-left: 36px;
  padding-right: 36px;
}

.link-tooltip {
  font-size: 12px;
}

.inline-code {
  background-color: var(--primary-30-color);
}

.code-section {
  border-radius: 4px;
  background-color: var(--secondary-10-color);
  color: var(--primary-60-color);
  padding-left: 16px;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, с 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
}

#letterSize {
  display: inline;
  position: absolute;
  opacity: 0;
  text-align: left;
  font: normal normal normal 14px/20px Roboto;
  letter-spacing: 0;
  padding: 8px;
}

.button-page {
  z-index: 1;
  position: absolute;
  width: 54px;
  height: 154px;
  display: flex;
  align-items: center;
  color: var(--primary-50-color);
  cursor: pointer;
}

.button-page:hover {
  background: #10255a52 0 0 no-repeat padding-box;
  color: white;
}

.prev-page {
  border-radius: 16px 0 0 16px;
  justify-content: flex-start;
  left: -40px;
}

.next-page {
  border-radius: 0 16px 16px 0;
  justify-content: flex-end;
  top: 0;
  right: -35px;
}

app-carousel {
  position: relative;
  z-index: 2;
}

app-vk-block li {
  margin-right: 4px;
}

.mat-tooltip-trigger {
  touch-action: auto !important;
}

em {
  background: var(--primary-30-color);
  font-style: normal;
}

app-catalog-description {
  em {
    background: transparent;
    font-style: italic;
  }
}
// убрал подцветку на мобилке при нажатии
a,
button,
tr,
td,
.loader-container {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.loader-container,
a,
tr,
td,
button:focus {
  outline: none !important;
}

.element-disabled {
  cursor: not-allowed;
  > * {
    pointer-events: none;
  }
}

.mat-dialog-responsive {
  .mat-dialog-container {
    max-height: 100vh;
    overflow-y: auto;
  }
}

@media screen and (max-width: 1024px) {
  app-vk-block li {
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 1020px) {
  .button-page {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .table-edge-margin {
    padding-left: 16px;
    padding-right: 16px;
  }
  .navbar {
    border-radius: 0 16px 16px 0;
    position: fixed;
    width: 0;
    top: 0;
    height: 100%;
    z-index: 100;
    background: var(--blue-gradient);
    overflow: hidden;
    transition: 0.3s ease;
    box-sizing: border-box;
  }
  .responsive-container {
    width: 100%;
  }
}

