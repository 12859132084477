.m0 {
  margin: 0 !important;
}

// RIGHT
.mr4 {
  margin-right: 4px;
}

.mr6 {
  margin-right: 6px;
}

.mr8 {
  margin-right: 8px;
}

.mr12 {
  margin-right: 12px;
}

.mr16 {
  margin-right: 16px;
}

.mr20 {
  margin-right: 20px;
}

.mr24 {
  margin-right: 24px;
}

.mr28 {
  margin-right: 28px;
}

.mr32 {
  margin-right: 32px;
}

.mr36 {
  margin-right: 36px;
}

// LEFT
.mlA {
  margin-left: auto;
}

.ml2 {
  margin-left: 2px;
}

.ml4 {
  margin-left: 4px;
}

.ml12 {
  margin-left: 12px;
}

.ml16 {
  margin-left: 16px;
}

.ml20 {
  margin-left: 20px;
}

.ml8 {
  margin-left: 8px;
}
.ml32 {
  margin-left: 32px;
}

.ml36 {
  margin-left: 36px;
}

// TOP

.mt3 {
  margin-top: 3px;
}

.mt4 {
  margin-top: 4px;
}


.mt8 {
  margin-top: 8px !important;
}

.mt12 {
  margin-top: 12px !important;
}

.mt16 {
  margin-top: 16px;
}

.mt18 {
  margin-top: 18px;
}

.mt20 {
  margin-top: 20px;
}
.mt24 {
  margin-top: 24px;
}

.ml24 {
  margin-left: 24px;
}

.mt32 {
  margin-top: 32px;
}

.mt40 {
  margin-top: 40px;
}


// BOTTOM
.mb1 {
  margin-bottom: 1px;
}

.mb2 {
  margin-bottom: 2px;
}

.mb3 {
  margin-bottom: 3px;
}

.mb4 {
  margin-bottom: 4px;
}

.mb8 {
  margin-bottom: 8px !important;
}

.mb10 {
  margin-bottom: 10px;
}

.mb12 {
  margin-bottom: 12px;
}

.mb16 {
  margin-bottom: 16px;
}

.mb24 {
  margin-bottom: 24px;
}

.mb32 {
  margin-bottom: 32px !important;
}
.mb40 {
  margin-bottom: 40px;
}
