.mat-list-text {
  padding-left: 5px !important;

  p {
    margin-top: -5px !important;
  }
}

.mat-dialog-container {
  padding: 0 !important;
}

.mat-tab-link {
  padding: 0 16px !important;
  min-width: 80px !important;
}

mat-option > .mat-option-text {
  display: inline-block;
  flex-grow: 1;
  overflow: auto;
  text-overflow: clip;
  line-height: 35px;
}

.mat-calendar-body-cell-content.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  background-color: white !important;
}
.mat-checkbox-inner-container {
  height: var(--mat-checkbox-size);
  width: var(--mat-checkbox-size);
  margin-right: var(--mat-checkbox-margin-right);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-select.paginator-input {
  .mat-select-arrow {
    color: var(--secondary-40-color);
  }
}

tr:first-child {
  th {
    border-bottom: solid 1px var(--secondary-30-color);
  }
}
tr {
  td.mat-cell {
    border-bottom: solid 1px var(--secondary-20-color);
  }
}

.mat-select-panel mat-option.mat-option {
  height: unset;
}

.mat-option-text.mat-option-text {
  white-space: normal;
}

.mat-container {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mat-tab-label-content,
a.mat-tab-link.mat-tab-label-active {
  opacity: 1 !important;
  color: #3c5b9c !important;
}

.mat-tab-label-content,
a.mat-tab-link {
  text-align: left;
  font: $font-normal;
  letter-spacing: 0;
  color: #696c71 !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 40px !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
  border-width: 1px !important;
}

.mat-menu-item {
  line-height: 20px !important;
}

.mat-menu-content {
  padding: 0 !important;
}

.mat-tab-body-content {
  padding: 30px;
  overflow: hidden !important;
}

.mat-tab-body-wrapper {
  background: #ffffff;
}

$mat-input-margin: 20px;

.mat-form-field-label.mat-empty {
  margin-left: 0 !important;
}

.mat-form-field-label {
  margin-left: calc(#{$mat-input-margin} - 5px) !important;
}

.mat-form-field.mat-focused {
  .mat-form-field-label {
    margin-left: calc(#{$mat-input-margin} - 5px) !important;
    color: var(--primary-50-color);
  }
  .mat-date-range-input-separator-hidden {
    opacity: 1 !important;
    animation: ani 1s forwards;
  }
}

@keyframes ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mat-form-field-outline-start {
  border-radius: $mat-input-margin 0 0 $mat-input-margin !important;
  min-width: $mat-input-margin !important;
}

.mat-form-field-outline-end {
  border-radius: 0 $mat-input-margin $mat-input-margin 0 !important;
}

mat-form-field.mat-form-field-type-mat-input {
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
}

.mat-tab-link-container {
  max-height: $mat-tab-link-max-height;
}

.mat-menu-panel {
  max-width: none !important;
  min-height: auto !important;
  border-radius: 0;
}

// DATEPICKER
.datepicker {
  margin-top: -3px;

  .mat-form-field-wrapper {
    padding-bottom: 0;
    margin: 0 !important;

    .mat-form-field-flex {
      height: 40px;

      .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label {
        top: 21px;
        left: 10px;
      }

      .mat-form-field-outline {
        height: 40px;

        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
          background-color: white;
        }
      }

      .mat-form-field-infix {
        padding: 0.3em 0 0.5em 0;
        top: -0.1em;
      }
      .mat-form-field-suffix {
        top: 0.42em;
      }
    }
  }

  .mat-form-field-infix {
    padding: 0.5em 0;
  }

  &.mat-form-field-should-float {
    .mat-form-field-flex {
      .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label {
        top: 25px;
        left: 1px;
      }

      .mat-form-field-outline {
        // color: var(--primary-40-color); // убрал для дейт пикера
      }
    }
  }
}

//

.incorrect-data mat-form-field {
  &.disabled {
    .mat-form-field-wrapper {
      background: var(--secondary-10-color);
      border-radius: 24px;
    }
  }
}

tr.mat-header-row {
  height: 45px !important;
}

tr.mat-row {
  height: 60px !important;
}

.mat-body p,
.mat-body-1 p,
.mat-typography p {
  margin: 0 !important;
}

mat-list > .list-container > mat-list-item > .mat-list-item-content {
  padding: 0 !important;
}

.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
  margin-right: 0 !important;
}

.mat-expansion-panel-header {
  padding: 0 22px 0 18px !important;
}

.mat-expansion-panel-header.mat-expanded {
  height: 56px !important;
}

.mat-expansion-panel-header {
  height: 56px !important;
}

.mat-expansion-panel-body {
  padding: 0 16px !important;
}

.mat-button-toggle-checked {
  background-color: var(--primary-50-color) !important;
  span,
  svg {
    color: white !important;
  }
}

.appeal-input .mat-form-field-flex {
  height: 43.5px;
}

.appeal-input.flex .mat-form-field-flex {
  height: auto;
}

.appeal-textarea .mat-form-field-flex {
  height: 82px;
}

.mat-placeholder-required.mat-form-field-required-marker {
  display: inline-block;
}

mat-label{
  color: var(--secondary-40-color);
  /*  font: normal normal normal 14px/20px Roboto;*/
}


.appeal-input,
.appeal-textarea {
  &.disabled {
    &.mat-form-field-should-float .mat-form-field-label {
      mat-label,
      .mat-placeholder-required {
        background: linear-gradient(to top, var(--secondary-10-color) 0%, var(--secondary-10-color) 50%, white 50%, white 100%);

        &::after {
          background: linear-gradient(to top, var(--secondary-10-color) 0%, var(--secondary-10-color) 50%, white 50%, white 100%);
          content: '';
          display: inline-block;
        }
      }
    }
  }

  &.select {
    .mat-form-field-wrapper {
      padding: 0;
      .mat-select-min-line {
        padding-left: 14px;
      }
      .mat-select-arrow-wrapper {
        transform: translateY(10%);
      }
    }
  }

  &.mat-form-field-appearance-outline:not(.mat-focused) .mat-form-field-flex:hover .mat-form-field-outline-thick {
    .mat-form-field-outline-start,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-end {
      border-width: 1px;
      border-color: var(--secondary-50-color);
    }
  }

  &.mat-form-field-should-float {
    .mat-form-field-outline-gap {
      border-top-style: none;
    }
  }

  &.mat-form-field-appearance-outline.mat-form-field-invalid .mat-form-field-outline-thick {
    .mat-form-field-outline-start,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-end {
      border-width: 1px;
      border-color: var(--negative-color);
    }

    .mat-form-field-label .mat-form-field-label {
      color: var(--negative-color);
    }
  }
  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: var(--primary-50-color);
  }
  &.mat-form-field-appearance-outline.mat-focused.mat-form-field-invalid .mat-form-field-outline-thick {
    .mat-form-field-outline-gap {
      border-top: none;
    }
  }

  &.mat-form-field-appearance-outline.mat-focused .mat-form-field-flex:hover .mat-form-field-outline-thick {
    .mat-form-field-outline-start,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-end {
      border-width: 1px;
      border-color: var(--primary-50-color);
      outline: none;
    }
  }

  &.mat-form-field-appearance-outline.mat-focused .mat-form-field-flex .mat-form-field-outline-thick {
    .mat-form-field-outline-start,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-end {
      border-width: 1px;
      border-color: var(--primary-50-color);
    }
  }

  &.mat-form-field-appearance-outline.mat-form-field-should-float .mat-form-field-flex:hover .mat-form-field-outline-thick,
  &.mat-form-field-appearance-outline.mat-focused .mat-form-field-flex:hover .mat-form-field-outline-thick,
  &.mat-form-field-appearance-outline.mat-focused .mat-form-field-flex .mat-form-field-outline-thick {
    .mat-form-field-outline-gap {
      border-top: none;
    }
  }

  &.mat-form-field-should-float:not(.mat-form-field-disabled).mat-focused
    .mat-form-field-wrapper
    .mat-form-field-flex
    .mat-form-field-infix
    .mat-form-field-label-wrapper
    .mat-form-field-label {
    color: var(--primary-50-color);
  }

  &.mat-form-field-invalid .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label {
    color: var(--negative-color);
  }

  &.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.19375em) translateX(-14px) scale(0.823) !important;
    mat-label,
    .mat-placeholder-required {
      background: white;
      &::after {
        background: white;
        content: '';
        display: inline-block;
      }
    }
  }

  .mat-form-field-flex {
    .mat-form-field-outline {
      color: var(--secondary-40-color);
    }

    .mat-form-field-infix {
      padding-top: 3px;

      .mat-form-field-label-wrapper {
        top: -19px;
        left: 10px;
        width: 93%;
        .mat-form-field-label {
          color: var(--secondary-40-color);
        }
      }
    }

    .mat-input-element {
      padding-left: 12px;
      max-width: 95%;
    }
  }

  &.mat-form-field-disabled {
    .mat-form-field-outline-start,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-end {
      background-color: var(--secondary-10-color);
    }
  }
}
.mat-table-sticky {
  z-index: 1 !important;
}
.appeal-select {
  .mat-select-placeholder {
    color: var(--secondary-40-color);
  }
}
.impaired {
  .mat-select-placeholder {
    color: var(--current-100-color);
  }
}

/* .impaired.error {
  .mat-select-placeholder {
    color: #ff0000;
  }
} */

.cdk-overlay-pane {
  &.mat-tooltip-panel {
    .mat-tooltip {
      background: var(--secondary-60-color) 0 0 no-repeat padding-box;
      box-shadow: 0 16px 32px #0e0e0f52;
      border-radius: 4px;
      color: var(--secondary-0-color);
      text-align: left;
      font: normal normal normal 14px/20px Roboto;
      max-width: 600px;
      word-break: break-all !important;
      white-space: normal !important;
    }
  }
}

.mat-paginator .mat-form-field-underline {
  display: none !important;
}

.mat-paginator mat-form-field .mat-form-field-infix {
  border: 1px solid #b2b5bb;
  border-radius: 20px;
  padding: 8px 8px 8px 16px;
}

.mat-paginator mat-form-field .mat-form-field-infix .mat-select-arrow {
  color: var(--secondary-40-color);
}

.mat-paginator-page-size-select {
  width: 68px !important;
}

.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  height: 4px;
  background-color: var(--primary-60-color) !important;
}

.mat-tab-link {
  height: auto !important;
}

.mat-tab-links {
  height: 40px;
}

.documents mat-dialog-container {
  border-radius: 4px !important;
}

.mat-dialog {
  transition: none;
}

.transparent-backdrop {
  opacity: 0;
}

.option-checkbox .mat-checkbox-layout {
  width: 100%;
  display: grid;
  grid-template-areas: '. label';
  grid-template-columns: 16px 1fr;
  grid-gap: 12px;
  position: relative;

  .mat-checkbox-inner-container {
    position: absolute;
    top: 1px;
    margin: 0;
  }

  .mat-checkbox-label {
    grid-area: label;
    font: normal normal normal 14px/20px Roboto;
    white-space: normal;
  }
}

.mat-checkbox-layout {
  white-space: normal;
}

.swagger-tabs.mat-tab-nav-bar.mat-primary {
  .mat-ink-bar {
    background-color: var(--accent-color) !important;
  }
  a.mat-tab-link.mat-tab-label-active {
    color: var(--accent-color) !important;
  }
}

.mat-paginator-container {
  align-items: baseline !important;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  width: 122%  !important;
}


@media screen and (max-width: 1366px) {
  .facebook-carousel {
    .carousel {
      height: 600px !important;
    }
  }
}

@media screen and (max-width: 1100px) {
  .mat-tab-links {
    height: 37px;
  }

}

@media screen and (max-width: 480px) {
  .cdk-overlay-pane {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  mat-dialog-container {
    border-radius: 16px 16px 16px 16px !important;
  }

  .mat-menu-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-tap-highlight-color: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    line-height: 48px;
    height: 48px;
    padding: 0 16px;
    text-align: left;
    text-decoration: none;
    max-width: 100%;
    position: relative;
  }
  .mat-menu-item::-moz-focus-inner {
    border: 0;
  }
  .mat-menu-item[disabled] {
    cursor: default;
  }
  [dir='rtl'] .mat-menu-item {
    text-align: right;
  }
  .mat-menu-item .mat-icon {
    margin-right: 16px;
    vertical-align: middle;
  }
  .mat-menu-item .mat-icon svg {
    vertical-align: top;
  }
  [dir='rtl'] .mat-menu-item .mat-icon {
    margin-left: 16px;
    margin-right: 0;
  }
  .mat-menu-item[disabled] {
    pointer-events: none;
  }
  .cdk-high-contrast-active .mat-menu-item {
    margin-top: 1px;
  }
  .cdk-high-contrast-active .mat-menu-item.cdk-program-focused,
  .cdk-high-contrast-active .mat-menu-item.cdk-keyboard-focused,
  .cdk-high-contrast-active .mat-menu-item-highlighted {
    outline: dotted 1px;
  }
  .mat-menu-item-submenu-trigger {
    padding-right: 32px;
  }
  [dir='rtl'] .mat-menu-item-submenu-trigger {
    padding-right: 16px;
    padding-left: 32px;
  }
  .mat-menu-submenu-icon {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    width: 5px;
    height: 10px;
    fill: currentColor;
  }
  [dir='rtl'] .mat-menu-submenu-icon {
    right: auto;
    left: 16px;
    transform: translateY(-50%) scaleX(-1);
  }
  .cdk-high-contrast-active .mat-menu-submenu-icon {
    fill: CanvasText;
  }
  button.mat-menu-item {
    width: 100%;
  }
  .mat-menu-item .mat-menu-ripple {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    pointer-events: none;
  }
}

@media screen and (max-width: 1023px) {
  .mat-expansion-panel-body {
    padding: 0 0 !important;
  }

  .mat-tab-link {
    padding: 0 8px !important;
    min-width: 72px !important;
  }
}
/*.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
border-left: solid 2px rgba(0, 0, 0, 0.12) !important;
}*/

@media screen and (max-width: 360px) {
  .mat-expansion-panel-body {
    padding: 0 !important;
  }
}
