$main-text-color: #a0bfee;
$main-site-color: #10265A;
$white-color: #FFFFFF;
$black-color: #0E0E0F;
$primary-color: #3C5B9C;
$font-bold: normal normal bold 14px/20px Roboto;
$font-normal: normal normal normal 14px/20px Roboto;
$font-normal-small: normal normal normal 12px/14px Roboto;
$catalog-table-header-height: 64px;
$catalog-table-header-margin: 16px;
$table-paginator-height: 60px;
$mat-tab-link-max-height: 40px;
$header-catalog-items-height: 103px;
$container-l: 1200px;
$container-m: 924px;
$container-s: 575px;
