@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-300.eot');
  src: local(''),
  url('/assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-300.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-300.woff2') format('woff2'),
  url('/assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-300.woff') format('woff'),
  url('/assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-300.ttf') format('truetype'),
  url('/assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-300.svg#Roboto') format('svg');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot');
  src: local(''),
  url('/assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'),
  url('/assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'),
  url('/assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'),
  url('/assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#Roboto') format('svg');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-500.eot');
  src: local(''),
  url('/assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-500.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-500.woff2') format('woff2'),
  url('/assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-500.woff') format('woff'),
  url('/assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-500.ttf') format('truetype'),
  url('/assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-500.svg#Roboto') format('svg');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-700.eot');
  src: local(''),
  url('/assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'),
  url('/assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'),
  url('/assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'),
  url('/assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-700.svg#Roboto') format('svg');
}
