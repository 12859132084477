.button2-impaired {
  @extend .button2-impaired-text;
  background: var(--current-0-color);
  border: 1px solid var(--current-100-color);
  padding: 0 var(--button-padding) 0 var(--button-padding);
  font-family: var(--root-font-family);
  min-height: var(--medium-size);
  display: flex;
  align-items: center;
  &:hover {
    background: var(--current-72-color) 0 0 no-repeat padding-box;
    color: var(--current-0-color);
  }

  &:active {
    background-color: var(--current-100-color);
    color: var(--current-0-color);
  }

  &:disabled {
    cursor: auto !important;
    border: 1px solid var(--current-24-color);
    color: var(--current-24-color);
    background: var(--current-0-color);
  }
}

.icon-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--current-100-color) 0 0 no-repeat padding-box;
  color: var(--current-0-color);
  border: 2px solid var(--current-100-color);
  font-family: var(--root-font-family);
  &:hover {
    background: var(--current-72-color) 0 0 no-repeat padding-box;
    border: 2px solid var(--current-100-color);
  }

  &:active {
    background: var(--current-100-color) 0 0 no-repeat padding-box;
    color: var(--current-0-color);
    border: 2px solid var(--current-100-color);
  }

  &:disabled {
    cursor: auto !important;
    background: var(--current-24-color) 0 0 no-repeat padding-box;
    border: 2px solid var(--current-24-color);
    color: var(--current-12-color);
  }
}

/* The container radio-button */
.radio-container {
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 12px;
  color: var(--current-100-color);
  font-size: 180%;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 120%;
  letter-spacing: var(--current-letter-spacing);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radio {
  position: absolute;
  top: -3px;
  left: 0;
  height: 26px;
  width: 26px;
  background-color: var(--current-100-color);
  border-radius: 50%;
}

.radio-container input:checked ~ .radio {
  background-color: var(--current-100-color);
}

.radio:after {
  content: '';
  position: absolute;
  display: none;
}

.radio-container input:checked ~ .radio:after {
  display: block;
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--current-0-color);
}

.radio-container input:not(checked) ~ .radio:after {
  display: block;
  top: 4px;
  left: 4px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: var(--current-0-color);
}

.radio-container:hover input ~ .radio {
  background-color: var(--current-56-color);
}

/* tabs */

.custom-tab {
  border: none;
  cursor: pointer;
  padding: 18px 24px;
  color: var(--current-100-color);
  background: var(--current-0-color);
  font-size: 180%;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 110%;
  letter-spacing: calc(0.6px + var(--current-letter-spacing));
  text-transform: uppercase;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */

  &.small {
    padding: 8px 12px;
    font-size: 160%;
    line-height: 150%;
  }

  &:hover {
    background: var(--current-12-color) 0 0 no-repeat padding-box;
    color: var(--current-100-color);
  }

  &:active {
    background: var(--current-24-color) 0 0 no-repeat padding-box;
    color: var(--current-100-color);
  }

  &.active {
    background: var(--current-100-color) 0 0 no-repeat padding-box;
    color: var(--current-0-color);
  }

  &:disabled {
    color: var(--current-24-color);
    background: var(--current-0-color) 0 0 no-repeat padding-box;
    border: none;
    cursor: none;
    pointer-events: none;
  }
}

.custom-tab.small-tab {
  font-size: 160%;
  line-height: 160%;
  padding: 7px 16px;
}

@media screen and (max-width: 768px) {
  .custom-tab {
    padding: 9px 12px;
  }
}
