@import '../style-variables';

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.btn-empty:focus {
  outline: none;
}

.btn-empty,
.btn-primary,
.btn-secondary {
  font-family: inherit;
  box-sizing: border-box;
  border: none;
  cursor: pointer;
}

.btn-empty {
  background: none;
  cursor: pointer;
  padding: 0;
}

.btn-primary {
  cursor: pointer;
  background-color: $primary-color;
  box-shadow: 0 2px 4px #0e0e0f29;
  border-radius: 24px;
  color: white;

  &:hover,
  &:focus {
    background-color: var(--primary-50-color);
  }

  &:active {
    background-color: var(--primary-50-color);
  }

  &:disabled {
    background-color: var(--secondary-30-color) !important;
    cursor: not-allowed !important;
    box-shadow: none !important;
  }
}

.btn-secondary {
  background-color: transparent;
  border-radius: 24px;
  color: $primary-color;
  border: 1px solid $primary-color;
  cursor: pointer;
  &:hover{
    color: var(--primary-50-color);
    border: 1px solid var(--primary-50-color);
  }

  &:active {
    background-color: var(--primary-30-color);
    color: var(--primary-70-color);
    border: 1px solid var(--primary-70-color);
  }

  &:disabled {
    background-color: transparent !important;
    color: var(--secondary-30-color) !important;
    border: 1px solid var(--secondary-30-color) !important;
    cursor: not-allowed !important;
    box-shadow: none !important;
  }
}

.btn-primary40 {
  color: var(--primary-40-color) !important;
  border: 1px solid var(--primary-40-color) !important;
}

.btn-chips {
  background-color: var(--primary-50-color);
  border-radius: 20px;
  color: white;
  border: none;

  &:hover,
  &:focus {
    background-color: var(--primary-60-color);
  }

  &:disabled {
    color: var(--secondary-30-color) !important;
    border: 1px solid var(--secondary-30-color) !important;
    cursor: not-allowed !important;
    box-shadow: none !important;
  }
}

.btn-chips.pressed {
  background-color: white;
  border: 1px solid var(--primary-60-color);
  color: var(--primary-60-color);

  &:hover,
  &:focus {
    background-color: var(--primary-60-color);
    color: white;
  }
}

.btn-chips,
.btn-chips.pressed {
  &:disabled {
    color: var(--secondary-30-color) !important;
    border: 1px solid var(--secondary-30-color) !important;
    cursor: not-allowed !important;
    box-shadow: none !important;
    background-color: white !important;
  }
}

// BUTTON SIZES

.btn-primary.big,
.btn-secondary.big {
  height: 48px;
  padding: 16px 24px;
}
.btn-chips.medium,
.btn-primary.medium,
.btn-secondary.medium {
  height: 40px;
  padding: 10px 20px;
}

.btn-primary.small,
.btn-secondary.small {
  height: 32px;
  padding: 6px 16px;
}

.toggle-group {
  border-radius: 20px !important;
  height: 40px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.toggle-btn {
  background-color: var(--secondary-10-color);
  color: var(--secondary-40-color);
}

.toggle-btn.active {
  color: white !important;
  background-color: #698cce;
  border-left: 1px solid #698cce !important;
  border-right: 1px solid #698cce !important;
}

.btn-with-icon {
  display: flex !important;
  align-items: center;
}

.custom-input:hover {
  border: 1px solid var(--secondary-50-color) !important;
}

.custom-input:focus-visible {
  outline: none;
  border: 1px solid var(--primary-60-color) !important;
}

.filter-btn,
.clear-filters-btn {
  width: 37px;
  box-sizing: border-box;
  padding: 7px;
  position: relative;
  z-index: 1;
  svg {
    z-index: 10;
  }
}

.clear-filters-btn:disabled{
  cursor: not-allowed;
}

.clear-filters-btn.active .close-round-icon,
.close-round-icon {
  color: var(--negative-color);
}

.clear-filters-btn.active .clear-filter-icon,
.clear-filter-icon {
  color: var(--secondary-50-color);
}


.clear-filters-btn:hover:enabled .interactive,
.clear-filters-btn:active:enabled .interactive {
  position: absolute;
  z-index: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.clear-filters-btn:hover:enabled .interactive {
  background-color: var(--secondary-30-color);
}

.clear-filters-btn:active:enabled .interactive {
  background-color: var(--secondary-40-color);
}


.clear-filter-icon,
.close-round-icon {
  color: var(--secondary-40-color);
}

.close-round-icon {
  position: absolute;
  bottom: 5px;
  right: -1px;
  border: 1px solid white;
  border-radius: 50%;
}
